import axios from "axios";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useRecoilState } from "recoil";
import { kbAuthState } from "states/KbState";
import { useAccountList } from "utils/clientAPIs/accountsCheck";
import { kbFetcherWithJWTV2 } from "utils/clientAPIs/kbAuth";

interface PasswordContextValue {
  encryptedPasswords: any;
  isLoading: boolean;
  accounts: any;
  isAccountLoading: boolean;
  currentAccount: any;
  setCurrentAccount: any;
  passwordError: number;
  setRefeshCurrentAccount: any;
  accountCancelSuccess: any;
  setAccountCancelSuccess: any;
  mutate: any;
  getPasswordFetch: any;
  mainScreenPasswordSuccess: boolean;
  setMainScreenPasswordSuccess: any;
  setReloadPassword: any;
  kbAuth: any;
  setKbAuth: any;
  setEncryptedPasswords: any;
}

const KBContext = createContext<PasswordContextValue | undefined>(undefined);
// 비밀번호 업데이트
export const updatePasswordToRedis = async (
  password: string,
  email: string | null,
  accountNo: string
) => {
  console.log("updatePasswordToRedis");
  // if (!session?.user?.email) return;
  if (!email) return;
  await fetch("/api/kb/securePassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      key: "encryptedPassword",
      value: password,
      email: email,
      accountNo: accountNo,
    }),
  });
};

export const KBProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  // 계좌 관련
  // 계좌 정보 가져오기
  const [accounts, isAllAccountLoading, mutate, error] = useAccountList();
  const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [currentAccount, setCurrentAccount] = useState<any>(null);
  const [accountCancelSuccess, setAccountCancelSuccess] = useState<any>(false);
  useState<boolean>(false);
  const [refeshCurrentAccount, setRefeshCurrentAccount] =
    useState<boolean>(false);

  // currentAccount 설정 -> Default:대표계좌

  useEffect(() => {
    if (accounts?.length > 0) {
      mutate();
    }
  }, [refeshCurrentAccount]);

  useEffect(() => {
    const fetchAccesstoken = async () => {
      if (session?.user?.email) {
        try {
          const response = await axios.post(
            `/api/kb/auth?email=${session?.user?.email}`,
            {},
            { withCredentials: true }
          );
          if (response.status === 200) {
            setKbAuth({
              status: true,
            });
          }
        } catch (e: any) {
          console.log("error", e);
          setKbAuth({
            status: false,
            error: e?.response?.data?.error || e?.response?.data,
            isTermsError: e?.response?.status === 422,
          });
        }
      }
    };
    if (currentAccount && !kbAuth) {
      fetchAccesstoken();
    }
  }, [currentAccount, kbAuth]);

  useEffect(() => {
    console.log("isAllAccountLoading", isAllAccountLoading);
    if (isAllAccountLoading) {
      return;
    }
    if (error) {
      setIsAccountLoading(false);
      return;
    }
    // if (!session) {
    //   return;
    // }
    // console.log("accounts", accounts);
    if (accounts?.length > 0) {
      const mainAccount =
        accounts?.find((account: any) => account.isMainAccount) || null;
      if (mainAccount) {
        setCurrentAccount(mainAccount);
      }
      setIsAccountLoading(false);
    } else {
      // 계좌가 없을 때
      if (session?.user?.email) {
        setIsAccountLoading(false);
      }
    }
  }, [isAllAccountLoading, error, session, accounts]);

  //////////////////////////////
  // 비밀번호 관련
  const [encryptedPasswords, setEncryptedPasswords] = useState<any>(null);
  const [passwordError, setPasswordError] = useState<number>(0);
  const [mainScreenPasswordSuccess, setMainScreenPasswordSuccess] =
    useState<boolean>(false);
  const [reloadPassword, setReloadPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // 캐시 비밀번호 불러오는 로딩

  const getPasswordFetch = async (accountNo: string, email: string) => {
    const res = await fetch(
      `/api/kb/securePassword?email=${email}&key=encryptedPassword&accountNo=${accountNo}`
    );
    const data = await res.json();
    const password = data?.password;
    // console.log("getPasswordFetch", password);
    return password;
  };

  // Redis 에서 캐시 비밀번호 가져오기 -> 비밀번호 전역 상태 업데이트
  const getPasswordFromRedis = async (
    accountNo: string,
    setIsLoading: any,
    email: string,
    setEncryptedPasswords: any
  ) => {
    setIsLoading(true);
    console.log("setIsLoading  true");
    const password = await getPasswordFetch(accountNo, email);
    if (password)
      setEncryptedPasswords((prev: any) => ({
        ...prev,
        [accountNo]: password,
      }));
    else {
      setEncryptedPasswords((prev: any) => ({
        ...prev,
        [accountNo]: null,
      }));
    }
    console.log("setIsLoading  false");

    setIsLoading(false);
  };

  const checkPasswordValidation = async (
    password: string,
    accountNo: string,
    router: any
  ) => {
    // 비밀번호 체크
    // const res = await fetch(
    //   `/api/kb/account/checkpassword?displayAccountNo=${accountNo}&password=${password}`
    // );
    // const data = await res.json();

    const res = await kbFetcherWithJWTV2(
      `/api/kb/account/checkpassword?displayAccountNo=${accountNo}&password=${password}`,
      {},
      session?.user?.email as string
    );
    const status = res?.status;
    const data = res?.data;

    if (res?.status === 422) {
      const cnt = data?.errorCnt;
      setPasswordError(cnt);
      setKbAuth({
        status: true,
      });
      return false;
    } else {
      if (res?.status === 200) {
        setKbAuth({
          status: true,
        });
        setPasswordError(0);
        setEncryptedPasswords((prev: any) => {
          if (!prev) return { [currentAccount.accountNo]: password };
          return {
            ...prev,
            [currentAccount.accountNo]: password,
          };
        });
        if (router.pathname === "/" && router?.query?.type === "explore") {
          setMainScreenPasswordSuccess(true);
        }
        updatePasswordToRedis(
          password,
          session?.user?.email as string,
          accountNo
        );
      } else if (res?.status === 401 || res?.status === 403) {
        setKbAuth({
          status: false,
          error: res?.data?.error || res?.data,
          isTermsError: false,
        });
      }
    }
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data && event.data.encryptedPassword) {
      const encryptedPasswordData = JSON.parse(event.data.encryptedPassword);
      const password = encryptedPasswordData.dataBody?.vkpad_value1;
      checkPasswordValidation(password, currentAccount.accountNo, router);
    } else {
      console.log("event.data.encryptedPassword is null");
    }
  };
  // 보안키패드 화면에서 비밀번호 GET
  useEffect(() => {
    if (status !== "authenticated") return; // 세션 데이터가 로드될 때까지 대기
    if (!currentAccount || !currentAccount?.accountNo) return;

    console.log("addEventListener");
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [session, currentAccount, router]);

  // Redis 에서 캐시 비밀번호 GET
  useEffect(() => {
    if (!accounts) return;
    if (!currentAccount || !currentAccount?.accountNo) return;
    if (session && session.user) {
      // if (!encryptedPasswords) console.log("getPasswordFromRedis");
      console.log("getPasswordFromRedis");
      getPasswordFromRedis(
        currentAccount.accountNo,
        setIsLoading,
        session.user.email as string,
        setEncryptedPasswords
      );
    }
  }, [session, currentAccount, reloadPassword]);

  return (
    <KBContext.Provider
      value={{
        encryptedPasswords,
        isLoading,
        accounts,
        isAccountLoading,
        currentAccount,
        setCurrentAccount,
        passwordError,
        setRefeshCurrentAccount,
        accountCancelSuccess,
        setAccountCancelSuccess,
        mutate,
        getPasswordFetch,
        mainScreenPasswordSuccess,
        setMainScreenPasswordSuccess,
        setReloadPassword,
        kbAuth,
        setKbAuth,
        setEncryptedPasswords,
      }}
    >
      {children}
    </KBContext.Provider>
  );
};

export const useKBGlobalState = (): PasswordContextValue => {
  const context = useContext(KBContext);
  if (!context) {
    throw new Error("KBProvider error");
  }
  return context;
};
