import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { CONTACTS, MENUS } from "data/footer";
import useHandleWeatherImageError from "utils/useHandleWeatherImageError";
import { TYPO } from "styles/Typo";

const Footer = () => {
  const router = useRouter();
  const handleImageError = useHandleWeatherImageError();

  return (
    <footer className="min-w-[360px] flex   bg-gray2 w-full px-[30px] pt-[30px] pb-[100px]">
      <div className="flex flex-col gap-5 text-gray60">
        {/* <Image
          onError={handleImageError}
          unoptimized
          quality={100}
          width={64}
          height={36}
          src={`/images/icons/footer/gne.svg`}
          alt="logo"
          // className=" mb-5"
        /> */}

        <h1 className={`${TYPO.TYPO8_SB} text-gray60`}>
          {router.locale == "ko" ? "(주)지엔이테크홀딩스" : "GNE Tech Holdings"}
        </h1>
        <ul className="flex flex-col gap-[6px]">
          <li className="flex items-center gap-2">
            <p className={`${TYPO.TYPO8_RG} text-gray50`}>
              {router.locale == "ko" ? "대표" : "CEO"}
            </p>
            <div className="h-[8px] w-[1px] bg-gray50"></div>
            <p className={`${TYPO.TYPO8_RG} text-gray60`}>
              {router.locale == "ko" ? "이재웅" : "Jay Lee"}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <p className={`${TYPO.TYPO8_RG} text-gray50`}>
              {router.locale == "ko" ? "사업자 등록 번호" : "Business Number"}
            </p>
            <div className="h-[8px] w-[1px] bg-gray50"></div>
            <p className={`${TYPO.TYPO8_RG} text-gray60`}>
              {router.locale == "ko" ? "429-86-02746" : "429-86-02746"}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <p className={`${TYPO.TYPO8_RG} text-gray50 shrink-0`}>
              {router.locale == "ko" ? "주소" : "Adress"}
            </p>
            <div className="h-[8px] w-[1px] bg-gray50"></div>
            <p className={`${TYPO.TYPO8_RG} text-gray60`}>
              {router.locale == "ko"
                ? "서울특별시 영등포구 의사당대로 83, 오투타워"
                : "83, Uisadang-daero, Yeongdeungpo-gu, Seoul, Republic of Korea"}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <p className={`${TYPO.TYPO8_RG} text-gray50`}>
              {router.locale == "ko" ? "이메일" : "Email"}
            </p>
            <div className="h-[8px] w-[1px] bg-gray50"></div>
            <p className={`${TYPO.TYPO8_RG} text-gray60`}>
              {router.locale == "ko" ? "support@gne.ai" : "support@gne.ai"}
            </p>
          </li>
          <li className="flex items-center gap-2">
            <p className={`${TYPO.TYPO8_RG} text-gray50`}>
              {router.locale == "ko" ? "데이터 제공" : "Powered by"}
            </p>
            <div className="h-[8px] w-[1px] bg-gray50"></div>
            <div className="flex items-center gap-2 ">
              <Image
                unoptimized
                src={`/images/footer/koscom.png`}
                alt="koscom"
                width={47}
                height={9.55}
              />
              <Image
                unoptimized
                src={`/images/footer/fis.png`}
                alt="fis"
                width={28.73}
                height={12}
              />
            </div>
          </li>
        </ul>
        <p
          className={`${TYPO.TYPO8_MD} text-gray60 cursor-pointer hover:underline`}
          onClick={() => {
            // router.push("/policy");
            window.open(
              "https://near-piccolo-e6f.notion.site/c46fbec5aa8045a964d92a3027a1d1",
              "_blank"
            );
          }}
        >
          {router.locale === "ko" ? "개인정보처리방침" : "Privacy Policy"}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
