import { useEffect } from "react";

export const useIosFixedBottomButton = () => {
    useEffect(() => {
        // 임시 div를 추가하여 스크롤 가능하게 만듦
        const tempDiv = document.createElement('div');
        tempDiv.style.height = '0.5px';
        document.body.appendChild(tempDiv);

        window.scrollTo(0, 1);

        // 청소
        return () => {
            window.scrollTo(0, 0);
            document.body.removeChild(tempDiv);

        };

    }, []);
}