// states/deviceState.ts
import { atom, useRecoilValue } from 'recoil';
import { v1 } from "uuid";


export const kbAuthState = atom<any>({
    key: `kbAuthState/${v1()}`, // 고유한 키
    default: false, // 기본값
});
export const useKbAuth = () => {
    const state = useRecoilValue(kbAuthState);
    return state;
}

export const accountCancelSuccessState = atom({
    key: `accountCancelSuccess/${v1()}`, // 고유한 키
    default: false, // 기본값
});


export const useAccountCancelSuccess = () => {
    const state = useRecoilValue(accountCancelSuccessState);
    return state;
}


export const passwordExpiredToastState = atom({
    key: `passwordExpiredToast/${v1()}`, // 고유한 키
    default: false, // 기본값
});


const useKbPasswordExpiredToast = () => {
    const isMobile = useRecoilValue(passwordExpiredToastState);
    return isMobile;
}

export default useKbPasswordExpiredToast;